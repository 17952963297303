<script lang="ts" setup>
import VOtpInput from 'vue3-otp-input';

const modelValue = defineModel<string>({ default: '' });

const props = defineProps<{
  error?: string;
  hint?: string;
}>();

const otpInput = ref<InstanceType<typeof VOtpInput> | null>(null);
const showBottomText = computed(() => !!props.error || !!props.hint);

const computedClasses = computed(() => [props.error ? 'otp-input__error' : '']);
</script>

<template>
  <div class="flex flex-col items-center">
    <v-otp-input
      ref="otpInput"
      v-model:value="modelValue"
      :input-classes="['otp-input', ...computedClasses]"
      :num-inputs="4"
      :placeholder="['0', '0', '0', '0']"
      :should-auto-focus="true"
      input-type="number"
      inputmode="tel"
      separator=""
    />

    <FoldableBlock v-model="showBottomText">
      <div class="text-xs pt-2 px-2">
        <span
          v-if="error"
          class="text-hell-red"
        >
          {{ error }}
        </span>

        <span v-else-if="hint">{{ hint }}</span>
      </div>
    </FoldableBlock>
  </div>
</template>

<style lang="scss">
.otp-input {
  width: 44px;
  height: 44px;
  margin: 0 12px;
  padding: 8px 11px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: $hell-black-5;
  color: $hell-black;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    border-color: $hell-black-20;
    outline: none;
  }

  &:focus {
    border-color: $hell-black-40;
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
  }

  &::placeholder {
    color: $hell-black-40;
  }

  &.is-complete {
    border-color: $hell-green; // Border color of an input field with value

    &.otp-input__error {
      border-color: $hell-red;
    }
  }
}
</style>

<script lang="ts" setup>
const authStore = useAuthStore();

if (authStore.isLogged) {
  await navigateTo('/profile');
}
</script>

<template>
  <div class="w-full h-full flex flex-col gap-4 justify-center items-center px-3">
    <CardBlock class="!p-6">
      <LoginPage />
    </CardBlock>
  </div>
</template>

<script lang="ts" setup>
import { useToast } from 'vue-toastification';
import { YandexSmartCaptcha } from '@gladesinger/vue3-yandex-smartcaptcha';
import ToastComponent from '~/components/ui/ToastComponent.vue';
import type { TelegramUser } from '~/types/User';
import type { YandexCaptchaResponse } from '~/server/api/yandex-captcha-validate.post';

withDefaults(defineProps<{ heading?: string; cancelRedirectToProfile?: boolean }>(), {
  heading: 'Войти или зарегистрироваться',
  cancelRedirectToProfile: false,
});

const authStore = useAuthStore();
const cartStore = useCartStore();
const checkoutStore = useCheckoutStore();
const profileStore = useProfileStore();
const gtm = useGtm();
const toast = useToast();

const step = ref(0);
const phone = ref('');
const code = ref('');
const error = ref('');
const timer = ref(0);
const isLoading = ref(false);

function startTimer() {
  timer.value = 30;

  const interval = setInterval(() => {
    if (timer.value > 0) timer.value--;
    if (timer.value === 0) clearInterval(interval);
  }, 1000);
}

const isTimerEnabled = computed(() => timer.value > 0);

const { executeRecaptcha } = useCaptcha();

const { yandexCaptchaClientKey } = useRuntimeConfig().public;
const yaCaptcha = ref<any>(null);
const yaCaptchaPassed = ref(false);

function executeYandexCaptcha() {
  if (yaCaptchaPassed.value) {
    handlePhoneSubmit();
  } else {
    yaCaptcha.value.execute();
  }
}

async function onCaptchaSuccess(spravka: string) {
  const { data } = await useFetch<YandexCaptchaResponse>('/api/yandex-captcha-validate', {
    method: 'POST',
    body: { token: spravka },
  });

  if (data.value && data.value.status === 'ok') {
    yaCaptchaPassed.value = true;
    await handlePhoneSubmit();
  }
}

function onCaptchaExpired() {
  console.log('yandex captcha token expired');
}

async function handlePhoneSubmit() {
  error.value = '';

  const unmaskedPhone = phone.value.replace(/\D/g, '');

  if (unmaskedPhone.length < 11) {
    error.value = 'Некорректный номер телефона';
    return;
  }

  const { token } = await executeRecaptcha('login');

  const response = await authStore.submitPhone(`+${unmaskedPhone}`, token);

  if (response?.status === 'success') {
    step.value = 1;
    startTimer();

    toast.success({ component: ToastComponent, props: { text: response.message } });
  } else {
    error.value = 'Не удалось отправить код';
  }
}

watch(
  () => phone.value,
  () => {
    error.value = '';
  },
);

watch(
  () => code.value,
  () => {
    error.value = '';
    if (code.value.length === 4) submitCode();
  },
);

function returnToStepZero() {
  step.value = 0;
  phone.value = phone.value.replace(/\D/g, '');
  code.value = '';
  error.value = '';
  timer.value = 0;
}

async function submitCode() {
  isLoading.value = true;

  const response = await authStore.validateCode(code.value);

  if (response?.status === 'success') {
    await profileStore.getUserInfo();

    await processLogin();
  } else if (response?.status === 'failed') {
    error.value = response.message;
  }

  isLoading.value = false;
}

const { phoneMaskOptions } = useInputMasks();

const { telegramBotId, telegramBotName } = useRuntimeConfig().public;

async function tgLogin() {
  if (!window.Telegram) return;

  window.Telegram.Login.auth(
    {
      bot_id: telegramBotId,
      request_access: 'write',
    },
    async (user: TelegramUser) => {
      if (!user) return;

      await authStore.tgLogin(user);
      await profileStore.getUserInfo();

      authStore.isLogged = profileStore.userDetails !== null;

      processLogin();
    },
  );
}

async function processLogin() {
  if (authStore.isLogged) {
    authStore.getAccessRights();
    cartStore.getCart();

    gtm?.push({
      event: 'login',
      country: 'Russia',
      city: checkoutStore.selectedLocation.data.city ?? checkoutStore.selectedLocation.data.settlement ?? null,
      discount_user: null,
    });

    const url = localStorage.getItem('return-url');
    localStorage.removeItem('return-url');

    await navigateTo(url ?? '/profile');
  } else {
    toast.error({ component: ToastComponent, props: { text: 'Не удалось авторизоваться' } });
  }
}
</script>

<template>
  <section class="login-page">
    <form
      v-if="step === 0"
      class="w-full"
      @submit.prevent="executeYandexCaptcha"
    >
      <h1
        v-if="heading"
        class="text-center mb-4"
      >
        {{ heading }}
      </h1>

      <p class="text-hell-black-60 text-center mb-4">Введите ваш номер телефона</p>

      <InputField
        v-model="phone"
        :error="error"
        :mask-options="phoneMaskOptions"
        class="mb-4"
        name="phone"
        placeholder="+7 999 999-99-99"
        type="tel"
        @submit="executeYandexCaptcha"
      />

      <TheBtn
        :disabled="!phone.length"
        class="w-full"
        type="submit"
      >
        Войти
      </TheBtn>

      <ClientOnly>
        <YandexSmartCaptcha
          ref="yaCaptcha"
          :siteKey="yandexCaptchaClientKey"
          hideShield
          invisible
          shieldPosition="bottom-right"
          @onSuccess="onCaptchaSuccess"
          @onTokenExpired="onCaptchaExpired"
        />
      </ClientOnly>

      <div class="flex gap-2 items-center w-full mt-4">
        <div class="h-[1px] w-full bg-hell-black-60" />
        <div class="text-hell-black-60 mb-0.5">или</div>
        <div class="h-[1px] w-full bg-hell-black-60" />
      </div>

      <section class="flex flex-col gap-2 mt-4 w-full">
        <button
          class="login-page__third-party-auth-block"
          name="vk-login"
          @click.prevent="authStore.vkLogin"
        >
          <SvgoSocialVk
            :font-controlled="false"
            class="size-7"
            filled
          />
          С помощью VK ID
        </button>

        <button
          class="login-page__third-party-auth-block"
          name="yandex-login"
          @click.prevent="authStore.yandexLogin"
        >
          <SvgoSocialYandex
            :font-controlled="false"
            class="size-7"
            filled
          />
          С помощью Яндекс ID
        </button>

        <button
          v-if="telegramBotId"
          class="login-page__third-party-auth-block"
          name="tg-login"
          @click.prevent="tgLogin"
        >
          <SvgoSocialTelegram
            :font-controlled="false"
            class="size-7"
            filled
          />
          С помощью Telegram
        </button>

        <div class="hidden">
          <TelegramLoginWidget
            :telegram-login="telegramBotName"
            @callback="tgLogin"
          />
        </div>
      </section>

      <p class="text-hell-black-60 text-xs mt-4">
        Продолжая использовать сайт, вы соглашаетесь с
        <AppLink
          class="underline"
          to="/info/politika-konfidencialnosti"
        >
          политикой использования персональных данных
        </AppLink>
      </p>
    </form>

    <form
      v-else-if="step === 1"
      class="w-full"
      @submit.prevent="handlePhoneSubmit"
    >
      <h1 class="text-center mb-4">Введите код из SMS</h1>

      <p class="text-hell-black-60 text-center mb-4">{{ phone }}</p>

      <OtpInput
        v-model="code"
        :error="error"
        class="mb-4 justify-center"
      />

      <FoldableBlock v-model="isTimerEnabled">
        <p class="text-sm text-center mb-4">Повторно отправить код можно через {{ timer }}c</p>
      </FoldableBlock>

      <div
        v-if="isLoading"
        class="flex justify-center items-center h-12"
      >
        <SvgoSpinner class="animate-spin text-3xl mt-1" />
      </div>

      <TheBtn
        v-else
        :disabled="timer !== 0"
        class="w-full"
        type="submit"
      >
        Отправить новый код
      </TheBtn>

      <button
        class="w-full mt-4 text-center text-sm underline text-hell-blue transition hover:text-label-violet"
        @click="returnToStepZero"
      >
        Изменить номер телефона
        <br />
        или войти через соцсети
      </button>
    </form>
  </section>
</template>

<style lang="scss" scoped>
.login-page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;

  @include screen-sm {
    max-width: 380px;
  }

  &__third-party-auth-block {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    padding: 12px;
    border: 1px solid $hell-black-10;
    border-radius: 12px;
    font-weight: $bold;
    font-size: $text-sm;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
